<template>
  <div>
    <a-button @click="visible=true" type="primary" style="margin-bottom:10px">添加车辆</a-button>
    <a-table :columns="columns" :data-source="busList" :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a @click.prevent="editBus(record)">编辑</a>
        <a-divider type="vertical" />
      </span>
    </a-table>
    <!-- 参数编辑 -->
    <a-modal v-model="visible" :title="edit?'编辑班车':'添加班车'" okText="确定" cancelText="取消" @ok="addMethod" @cancel="addBus={userStatus:true};edit=false">
      <p class="flex">
        <label class="type-name">车牌号</label> 
        <a-input class="flex1" v-model="addBus.carNumber" placeholder="车牌" :maxLength="8" />
      </p>
      <p class="flex">
        <label class="type-name">限乘人数</label> 
        <a-input class="flex1" v-model="addBus.seatNum" placeholder="人数" :maxLength="2" />
      </p>
      <p>
        <label class="type-name">状态</label>
        <a-switch un-checked-children="停用" :checked="addBus.userStatus" @click="addBus.userStatus = !addBus.userStatus" />
      </p>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: '车牌',
    dataIndex: 'carNumber',
    key: 'carNumber',
    width:180
  },
  {
    title: '限乘人数',
    dataIndex: 'seatNum',
    key: 'seatNum',
    width:120
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    key: 'statusName',
    width:100
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  data() {
    return {
      visible:false,
      columns,
      addBus:{
        userStatus:true,
        statusId:1,
      },
      busList:[],
      edit:false,
    }
  },
  mounted(){
    this.getBusList()
  },
  methods: {
    editBus(item){
      this.edit = true
      this.visible = true
      this.addBus.carNumber = item.carNumber
      this.addBus.seatNum = item.seatNum
      this.addBus.id = item.id
      this.addBus.userStatus = this.addBus.statusId==1?true:false
    },
    getBusList(){
      this.$post(this.$url.班车车辆列表,{}).then(data => {
        this.busList = data.data.list
      }).catch(() => {
          
      });
    },
    addMethod(){
      this.addBus.statusId = this.addBus.userStatus?1:0
      if(this.edit){
        this.editMethod()
        return;
      }
      this.$post(this.$url.班车车辆添加, this.addBus ).then(data => {
        this.$tips('添加成功','车辆添加成功！','success')
        this.visible = false
        this.getBusList()
      }).catch(() => {
        this.$tips('设置失败','车辆添加失败，重新操作','error')
      });
    },
    editMethod(){
      this.$post(this.$url.班车车辆编辑, this.addBus ).then(data => {
        this.$tips('操作成功','车辆编辑成功！','success')
        this.visible = false
        this.getBusList()
      }).catch(() => {
        this.$tips('操作失败','车辆编辑失败，重新操作','error')
      });
    },
  },
}
</script>
<style scoped>

</style>